.MuiPaper-root img {
  width: 29px;
}
.MuiInputAdornment-root img {
  width: 16px;
}

.MuiInputAdornment-root {
  padding-right: 10px;
}

.MuiBox-root a {
  margin: 0 auto;
}

.MuiModal-root {
  z-index: 1400 !important;
}

.jw-flag-floating .jw-wrapper {
  z-index: 999 !important;
}


button.AcceptUI {
  background: black;
  width: 100%;
  color: #fff;
  padding: 9px;
  font-size: 14px;
  border-radius: 6px;
  font-weight: 500;
}

#AcceptUIContainer {
  margin-top: 0px !important;
  top: 100px !important;
}

#AcceptUIContainer {
  max-width:0;
}

#AcceptUIContainer.show {
  max-width:100%;
}

.floating-placeholder-cc[_ngcontent-c2] input[_ngcontent-c2] {
  z-index: 999999 !important;
}


.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: -2px !important;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  font-size: 18px !important;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: -2px !important;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  font-size: 18px !important;
}
 
#card_profile_collection::before {
  content:  " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right,#002f4b,#000);
  opacity: .5; 
  z-index: 0;
}